.documentation-sidebar-container {
  padding: 2rem;
  height: 100%;
}
.documentation-nav {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-decoration: none;
}
.documentation-nav-container {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
  transition: all ease 0.2s;
  border-left: 2px solid transparent;
}
.documentation-nav-text {
  display: flex;
  height: fit-content;
  width: fit-content;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.7;
  color: black;
  transition: all ease 0.2s;
}
.documentation-nav-container:hover {
  padding-left: 1rem;
  border-left: 2px solid #ccc;
}
.documentation-nav-container:hover .documentation-nav-text {
  opacity: 1;
}
.documentation-nav-logo-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.documentation-nav-logo {
  display: flex;
  width: 10rem;
  height: 100%;
}
