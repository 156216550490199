.landingPage {
  background: #0b335b;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  transition: all ease 0.5s;
  position: relative;
}
.landingPage-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}
.landingPage-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-direction: column;
}
.landingPage-icon {
  margin-left: 4rem;
  height: 35rem;
  width: 100%;
  opacity: 1;
}
.landingPage-button {
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1000;
}
#button-api {
  z-index: 1000;
}
#button-dashboard {
  z-index: 1000;
}
.landingPage-finfetch-icon {
  display: flex;
  height: 22rem;
  width: 100%;
  border-radius: 50%;
  opacity: 1;
  box-shadow: 0 2rem 3rem #0000004c;
}
.landingPage-icon-container {
  display: flex;
  width: fit-content;
}
.landingPage-name {
  font-size: 4rem;
  color: black;
  font-weight: 500;
  opacity: 0.7;
  transition: all ease 0.5s;
}
.landingPage-apps {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}
.landingPage-card-container {
  display: flex;
  position: relative;
  column-gap: 3rem;
}
.landingPage-card-container:hover .landingPage-card {
  filter: blur(5px);
  opacity: 0.8;
}
.landingPage-card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 28rem;
  width: 16rem;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  box-shadow: 0 0.5rem 1rem #0000004c;
  top: 0;
  transition: all ease 0.3s;
}
.landingPage-construction {
  position: absolute;
  z-index: 100;
  width: 30.5rem;
  height: fit-content;
  background-color: rgba(255, 255, 0, 0.8);
  backdrop-filter: blur(5px);
  transform: rotate(54deg);
  transform-origin: top left;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  column-gap: 1rem;
  color: rgba(0, 0, 0, 0.795);
  font-weight: 500;
  padding: 0.5rem;
  box-shadow: 0 0.5rem 1rem #0000004c;
}
.landingPage-app-dashboard {
  border: 1px solid #5c4eb3;
}
.landingPage-app-api {
  border: 1px solid #0982cc;
}
.landingPage-card-container .landingPage-card:hover {
  top: -8px;
  transform: scale(1.05);
  box-shadow: 0 0.5rem 2rem #0000008b;
  filter: blur(0);
  opacity: 1;
}
.landingPage-card-icon {
  display: flex;
  height: 100%;
  justify-content: center;
}
.landingPage-card-icon-api {
  height: 9rem;
  margin-top: 2rem;
}
.landingPage-card-icon-dash {
  height: 9rem;
  margin-top: 2rem;
}
.landingPage-card-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 2rem;
}
.landingPage-circle-api {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 35%;
  background: linear-gradient(135deg, #0b335b, #0982cc 60%);
  clip-path: circle(160px at center -30px);
}
.landingPage-circle-dash {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 35%;
  background: linear-gradient(135deg, #0b335b, #5c4eb3 60%);
  clip-path: circle(160px at center -30px);
}
.landingPage-app-name {
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  color: #ffffff;
}
.landingPage-bold {
  font-size: 1.6rem;
  font-weight: bold;
}
.landingPage-logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  transition: all 0.2ms;
}
.landingPage-finfetch-text {
  display: flex;
  height: 100%;
  width: 15rem;
}

.landingPage-card-content {
  display: flex;
  height: 75%;
  justify-content: center;
  align-items: center;
  color: black;
  flex-direction: column;
}
.landingPage-card-button-dashboard {
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: auto;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #5c4eb3;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 25px;
}
.landingPage-card-button-api {
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: auto;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #0982cc;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 25px;
}
#landingPage-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: display ease 0.3s;
  row-gap: 1.5rem;
  animation: fadeIn ease 0.8s;
}
#landingPage-dashboard {
  display: none;
  transition: display ease 0.3s;
  animation: fadeIn ease 0.8s;
}
#landingPage-api {
  display: none;
  transition: display ease 0.3s;
  animation: fadeIn ease 0.8s;
}
.landingPage-mobile {
  display: none;
}
.landingPage-api-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #0982cc;
  transition: all ease 0.3s;
}
.landingPage-dash-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #d8dbde;
  transition: all ease 0.3s;
}
.landingPage-screen-icon {
  height: 11rem;
  margin-top: 2rem;
  -webkit-animation: pulse 1.5s infinite ease-in-out;
  -o-animation: pulse 1.5s infinite ease-in-out;
  -ms-animation: pulse 1.5s infinite ease-in-out;
  -moz-animation: pulse 1.5s infinite ease-in-out;
  animation: pulse 1.5s infinite ease-in-out;
}
.screen-on {
  opacity: 1;
  animation: expand 0.3s ease;
  z-index: 10000;
}
.screen-off {
  opacity: 0;
}
@keyframes expand {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media all and (max-width: 600px) {
  .landingPage {
    background: #0b335b;
    display: flex;
    flex-direction: column;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .landingPage-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .landingPage-apps {
    width: 100%;
  }
  #landingPage-api {
    display: none;
  }
  #landingPage-dashboard {
    display: none;
  }
  #landingPage-main {
    display: none;
  }
  .landingPage-card-container {
    column-gap: 0rem;
  }
  .landingPage-card {
    height: 17.5rem;
    width: 10rem;
    border-radius: 20px;
  }
  .landingPage-construction {
    transform: rotate(53deg);
    width: 18.5rem;
    font-size: 14px;
    padding: 0.2rem;
  }
  .landingPage-app-dashboard {
    border: 1px solid #5c4eb3;
  }
  .landingPage-app-api {
    border: 1px solid #0982cc;
  }
  .landingPage-card-container:hover .landingPage-card {
    filter: none;
    opacity: 1;
  }
  .landingPage-card-container .landingPage-card:hover {
    top: 0;
    transform: none;
    box-shadow: none;
    filter: blur(0);
    box-shadow: 0 0.5rem 1rem #0000004c;
    opacity: 1;
  }
  .landingPage-text {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
  }
  .landingPage-screen-icon {
    height: 7rem;
  }
  .landingPage-circle-dash {
    clip-path: circle(100px at center -10px);
  }
  .landingPage-circle-api {
    clip-path: circle(100px at center -10px);
  }
  .landingPage-app-name {
    font-size: 18px;
    font-weight: 400;
    margin-top: 1rem;
  }
  .landingPage-bold {
    font-size: 22px;
    font-weight: 500;
  }
  .landingPage-card-content {
    height: 60%;
  }
  .landingPage-card-icon-api {
    height: 5rem;
    margin-top: 1rem;
  }
  .landingPage-card-icon-dash {
    height: 5rem;
    margin-top: 1rem;
  }
  .landingPage-card-button {
    margin-bottom: 0;
  }
  .landingPage-card-button-dashboard {
    font-size: 16px;
  }
  .landingPage-card-button-api {
    font-size: 16px;
  }
  .landingPage-logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    transform: scale(0.9);
  }
  .landingPage-finfetch-letter {
    position: absolute;
    height: 80%;
    top: 50%;
    left: 27.5%;
    opacity: 1;
    transform: translate(-50%, -50%);
    animation: letterMove ease-in-out 2s;
  }
  .landingPage-finfetch-text {
    width: 100%;
    height: 100%;
    transform: translateX(1.5rem);
    clip-path: inset(0 0 0 0);
    animation: logoReveal ease-in-out 2s;
  }
  .landingPage-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .landingPage-mobile-text {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    column-gap: 0.7rem;
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
  }
  .landingPage-finfetch-text {
    height: 100%;
    width: 12rem;
  }
  .landingPage-api-screen {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .landingPage-dash-screen {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .app-reveal-1 {
    animation: reveal-1 1s ease;
  }
  .app-reveal-2 {
    animation: reveal-2 1s ease;
  }
  @keyframes letterMove {
    0% {
      left: 50%;
      opacity: 0;
      height: 80%;
    }
    30% {
      left: 50%;
      opacity: 1;
      height: 105%;
    }
    55% {
      left: 50%;
      opacity: 1;
      height: 80%;
    }
    100% {
      left: 27.5%;
      opacity: 1;
      height: 80%;
    }
  }
  @keyframes logoReveal {
    0% {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
    }
    30% {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
    }
    70% {
      clip-path: inset(0 0 0 100%);
      opacity: 0;
    }
    100% {
      clip-path: inset(0 0 0 0);
      opacity: 1;
    }
  }
}
