::-webkit-scrollbar {
  height: 8px;
  width: 8px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #c5c5c5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
* {
  box-sizing: border-box;
  outline: none;
}
.app {
  height: 100svh;
  width: 100lvw;
  overflow-x: hidden;
}
body,
html {
  margin: 0;
  overflow: overlay;
  scroll-behavior: smooth !important;
}
html:focus-within {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
a,
button {
  -webkit-tap-highlight-color: transparent;
}
.form-input {
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.5rem;
}
.form-label {
  font-size: 14px;
}
.form-checkbox {
  height: 18px;
  width: 18px;
}
.form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.7rem;
  font-size: 18px;
  border-radius: 25px;
  border: none;
  color: #0b335b;
  font-weight: bold;
  cursor: pointer;
}
.form-button:disabled {
  cursor: auto;
  pointer-events: all !important;
}
.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.offscreen {
  position: absolute;
  left: -9999px;
}
#confirmnote {
  margin-bottom: 0;
}
.bg-animation {
  position: absolute;
  transform: rotate(45deg);
  height: 100%;
  width: 100%;
}
.line {
  display: inline-block;
  text-decoration: underline;
  color: #ffffff;
}
.required {
  font-size: 12px;
  opacity: 0.8;
}
.page-link {
  text-decoration: none;
  font-weight: bold;
  color: #0b335b;
}
.bg-layer-1 {
  position: absolute;
  background-color: #fff;
  opacity: 0.1;
  width: 400%;
  height: 400%;
  animation: diagonal-1 9s ease-in-out infinite alternate;
}
.bg-layer-2 {
  position: absolute;
  background-color: #fff;
  opacity: 0.1;
  width: 400%;
  height: 400%;
  animation: diagonal-2 10s ease-in-out infinite alternate;
}
.bg-layer-3 {
  position: absolute;
  background-color: #0b335b;
  opacity: 0.1;
  width: 400%;
  height: 400%;
  animation: diagonal-1 9s ease-in-out infinite alternate;
}
.bg-layer-4 {
  position: absolute;
  background-color: #0b335b;
  opacity: 0.1;
  width: 400%;
  height: 400%;
  animation: diagonal-2 10s ease-in-out infinite alternate;
}
.loading-horizontal {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading-horizontal span {
  display: inline-block;
  vertical-align: middle;
  width: 0.6rem;
  height: 0.6rem;
  margin: 0.35rem;
  background: #fff;
  border-radius: 0.6rem;
  animation: loading-horizontal 1s infinite alternate;
}
.loading-horizontal span:nth-of-type(2) {
  background: #fff;
  animation-delay: 0.2s;
}
.loading-horizontal span:nth-of-type(3) {
  background: #fff;
  animation-delay: 0.4s;
}
.loading-horizontal span:nth-of-type(4) {
  background: #fff;
  animation-delay: 0.6s;
}
.loading-horizontal span:nth-of-type(5) {
  background: #fff;
  animation-delay: 0.8s;
}
.loading-horizontal span:nth-of-type(6) {
  background: #fff;
  animation-delay: 1s;
}
.loading-horizontal span:nth-of-type(7) {
  background: #fff;
  animation-delay: 1.2s;
}
.success-text {
  font-size: 16px;
}
.success-off {
  opacity: 0;
  transform: translate(-50%, 80px);
}
.success-on {
  opacity: 1;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 1rem;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes reveal-1 {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes reveal-2 {
  0% {
    transform: translateX(-80%);
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(720deg);
  }
  100% {
    transform: rotateX(1080deg);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  40% {
    transform: translateY(-50px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #7ac142;
  }
}

@keyframes diagonal-1 {
  0% {
    transform: translate(850px, -1200px);
  }

  100% {
    transform: translate(1000px, -1200px);
  }
}
@keyframes diagonal-2 {
  0% {
    transform: translate(900px, -1200px);
  }

  100% {
    transform: translate(1110px, -1200px);
  }
}
@keyframes loading-horizontal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opacity {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@media all and (max-width: 600px) {
  html,
  body {
    -webkit-text-size-adjust: 100%;
  }
  .form-label {
    font-size: 16px;
  }
  .bg-layer-1 {
    animation: diagonal-1 9s ease-in-out infinite alternate;
  }
  .bg-layer-2 {
    animation: diagonal-2 10s ease-in-out infinite alternate;
  }
  input,
  select,
  textarea {
    font-size: 16px;
  }
  .hidden {
    opacity: 0;
    transition: all 1s;
  }
  .reveal {
    opacity: 1;
  }
  @keyframes diagonal-1 {
    0% {
      transform: translate(400px, -1200px);
    }

    100% {
      transform: translate(200px, -1200px);
    }
  }
  @keyframes diagonal-2 {
    0% {
      transform: translate(350px, -1200px);
    }

    100% {
      transform: translate(180px, -1200px);
    }
  }
}
