.ticker-wrap {
  position: fixed;
  overflow: hidden;
  white-space: nowrap;
  background-color: black;
  color: #fff;
  opacity: 0.7;
  box-sizing: content-box;
  width: 100%;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 100;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.325);
  animation: dropDown 1s ease;
}

.ticker-content {
  display: inline-block;
  animation: marquee 40s linear infinite, tickerFade 1.5s ease;
  animation-delay: 1s, 0s;
}

.item-collection-1 {
  position: relative;
  left: 0%;
  float: left;
}

.item-collection-1 span,
.item-collection-2 span {
  display: block;
  font-size: 16px;
  text-align: center;
  width: 120px;
  margin: 0px;
}

.ticker-content:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.item {
  display: inline-block;
  width: 130px;
  vertical-align: top;
  margin-left: 15px;
  overflow: hidden;
}

.item span:first-child,
.item span:nth-child(2) {
  font-weight: bold;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes tickerFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dropDown {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0);
  }
}
.color-neutral {
  color: #ffae00;
}

.color-positive {
  color: #2cc357;
  font-weight: normal;
}

.color-negative {
  color: #ff0000;
  font-weight: normal;
}

.company-percentage {
  text-transform: capitalize;
  font-weight: bold;
  display: block;
  font-size: 20px;
}
@media all and (max-width: 600px) {
  .ticker-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .ticker-content:hover {
    animation-play-state: running;
  }
  .item {
    width: 100px;
  }
}
