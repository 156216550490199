.api-navbar {
  background: #0b335b;
  display: flex;
  color: #fff;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.325);
  width: 100vw;
  height: 5rem;
  padding: 0;
  align-items: center;
  align-content: center;
  z-index: 1000;
}
.api-navbar-container {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100%;
  width: 100%;
  align-items: center;
}
.api-navbar-icon {
  display: flex;
  width: 20%;
  align-items: flex-start;
}
.api-navbar-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.api-navbar-finfetch-icon {
  height: 3rem;
  transition: all ease 0.3s;
  align-items: center;
  justify-content: center;
}
.api-navbar-finfetch-icon:hover {
  transform: scale(1.05);
}
.api-navbar-links {
  column-gap: 1rem;
  height: 100%;
  display: flex;
  width: 80%;
  justify-content: flex-end;
}
.api-navbar-links .active {
  .api-nav-button {
    border-bottom: 3px solid #ffffff;
    opacity: 1;
    transition: all ease 0.2s;
    padding-top: 3px;
  }
}
.api-nav-button {
  padding-top: 3px;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  height: 100%;
  width: 7rem;
  opacity: 0.8;
  border-bottom: 3px solid transparent;
  transition: all ease 0.2s;
}
.api-nav-button:hover {
  opacity: 1;
}

.api-logout-button {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  height: 100%;
  width: 6rem;
  opacity: 0.8;
  border-bottom: 3px solid transparent;
  transition: all ease 0.2s;
}
.api-logout-button:hover {
  cursor: pointer;
  opacity: 1;
}
.api-logout-success {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  height: 4rem;
  width: fit-content;
  column-gap: 0.5rem;
  border-radius: 10px;
  background: #0b335b;
  color: #fff;
  font-size: 20px;
  bottom: 0;
  left: 50%;
  transition: all ease 0.4s;
}

@media all and (max-width: 600px) {
  .api-navbar-container {
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
  }
  .api-nav-button {
    font-size: 18px;
    font-weight: 500;
    width: 5rem;
  }
  .api-navbar-icon {
    align-items: center;
    justify-content: center;
    width: fit-content;
  }
  .api-navbar-links {
    width: fit-content;
  }
  .api-navbar-finfetch-icon {
    height: 2.5rem;
  }
}
