.app-api {
  background-color: #fff;
}
.app-api-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

@media all and (max-width: 600px) {
  .app-api-container {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
}
