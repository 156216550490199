.register-icon {
  height: 4rem;
}
.register-container {
  color: #ffffff;
  width: 24rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 2rem;
  background-color: #0b335b;
  box-shadow: 0 0.5rem 2rem #6464648b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 1000;
}
.register-header {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.register-header-text {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 2rem;
}
.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  row-gap: 0.5rem;
}
.register-input {
  border: 2px solid #ccc;
  background: #f2f3f5;
  box-shadow: 0 0 7px transparent;
  transition: all ease 0.3s;
}
.register-input:focus {
  background: #fff;
  border: 2px solid #0982cc;
  box-shadow: 0 0 7px #0982cc;
}
.register-form-button {
  margin-top: 1rem;
}
.instructions {
  font-size: 14px;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.5rem;
  position: relative;
}

.instructions > svg {
  margin-right: 0.5rem;
}

.register-hide {
  display: none;
}

.register-valid {
  color: limegreen;
  margin-left: 0.5rem;
}

.register-invalid {
  color: red;
  margin-left: 0.5rem;
}
@media all and (max-width: 600px) {
  .register-container {
    width: 100%;
  }
}
