.register {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all ease 0.5s;
  overflow: hidden;
  background: #ececec;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 600px) {
  .register {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
