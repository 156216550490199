.documentation {
  background-color: #f2f3f5;
  position: relative;
  height: 100%;
  width: 100vw;
  display: flex;
  overflow: hidden;
}
.documentation-header {
  height: 5rem;
  width: 100%;
}
.documentation-api-header-example {
  background-color: #fff;
  height: fit-content;
  width: fit-content;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: #0b335b;
  border: 1px solid #ccc;
}
.documentation-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.documentation-content-container {
  display: flex;
  scroll-behavior: smooth !important;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
}
.documentation-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  scroll-margin-top: 15rem;
  row-gap: 1rem;
}
.documentation-content:last-of-type {
  border-bottom: none;
}
.documentation-content:nth-of-type(4) {
  margin-bottom: 2rem;
}
.documentation-content-top {
  display: flex;
  width: 65rem;
  column-gap: 2rem;
}

.documentation-content-wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.documentation-instructions-text {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 30rem;
  font-size: 16px;
}
.documentation-text {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 40%;
  font-size: 16px;
}
.documentation-content-text {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.documentation-code {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  row-gap: 1.5rem;
  width: 60%;
  z-index: 0;
  height: 100%;
}
.documentation-code-header {
  font-weight: bold;
  color: #0b335b;
  width: 100%;
}
.documentation-syntax-highlighter {
  display: flex;
  width: 100%;
  height: 16rem;
}
.syntax-highlighter {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 100%;
  margin: 0;
}
.documentation-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 325px;
  min-width: 325px;
  background-color: #fff;
  border-right: 1px solid #ccc;
}

.documentation-content-header {
  display: flex;
  align-items: center;
  font-size: 30px;
  height: fit-content;
  width: fit-content;
  color: #0b335b;
  column-gap: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}
.documentation-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  column-gap: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background: url(../../../../assets/API-banner.png);
  background-color: #0b335b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.documentation-register-header {
  display: flex;
  font-size: 30px;
  color: #fff;
  column-gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.finfetch-mobile-icon {
  display: none;
}
.documentation-content-header-text {
  display: flex;
  height: fit-content;
  width: fit-content;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  column-gap: 1rem;
  color: #0b335b;
}
.request-type-get {
  width: fit-content;
  justify-content: center;
  align-items: center;
  border: solid 2px rgb(30, 144, 255);
  height: fit-content;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  border-radius: 5px;
  font-size: 14px;
  background-color: rgba(30, 144, 255, 0.15);
  font-weight: 500;
  color: rgb(30, 144, 255);
}

.documentation-register-text {
  display: flex;
  align-items: center;
  height: 3rem;
  column-gap: 0.5rem;
  font-size: 28px;
  font-weight: 500;
  color: #fff;
}
.documentation-register-button {
  display: grid;
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  grid-template-areas:
    "icon"
    "button";
  flex-direction: column;
  align-items: center;
}
#documentation-register-icon {
  display: flex;
  grid-area: icon;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#register-button-container {
  grid-area: button;
  z-index: 100;
}
#register-button-container:hover + #documentation-register-icon {
  opacity: 1;
  transform: translateY(0px);
}

.key-icon {
  display: flex;
  height: 2.5rem;
  margin-bottom: 1rem;
}
.register-button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 18px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: #0b335b;
  font-weight: 500;
  z-index: 1000;
}
.documentation-top {
  display: flex;
  position: absolute;
  top: 90vh; // How far should the scroll travel prior to the link appearing
  right: 2rem;
  bottom: 0;
  width: 3em;
  z-index: 1000;
}
.top-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  position: fixed;
  top: calc(100vh - 5rem);
  text-align: center;
  width: 3rem;
  height: 3rem;
  border-radius: 10%;
  padding: 0.25rem;
  background-color: rgba(0, 0, 0, 0.29);
}
.documentation-content-bottom {
  display: flex;
  flex-direction: column;
  width: 65rem;
}
.documentation-details {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  row-gap: 1.5rem;
  border-left: 1px solid #ccc;
}
.documentation-parameters {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.documentation-footer {
  display: flex;
  height: fit-content;
}
.parameters {
  padding-left: 1rem;
}
.strong-blue {
  color: #0b335b;
}
.typewriter {
  font-size: 28px;
  padding-top: 0.1rem;
  font-weight: 300;
}

// Mobile CSS

@media all and (max-width: 600px) {
  .documentation {
    height: 100%;
  }
  .documentation-sidebar {
    display: none;
  }
  .documentation-content-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .documentation-register {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    row-gap: 2rem;
  }
  .finfetch-mobile-icon {
    display: flex;
    justify-content: center;
  }
  .finfetch-text-icon {
    display: none;
  }
  .documentation-register-header {
    font-size: 28px;
    flex-direction: column;
  }
  .documentation-finfetch-logo {
    width: 10rem;
    height: 100%;
  }
  .mobile-font-header {
    font-weight: 300;
  }
  #documentation-register-icon {
    opacity: 1;
    animation: bounce 1.5s ease;
    transform: none;
  }
  .key-icon {
    transform-style: preserve-3d;
    animation: flip 1.3s linear;
  }
  .documentation-content {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .documentation-content-container {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .documentation-content-header {
    font-size: 30px;
  }
  .documentation-content-header-text {
    font-size: 26px;
    column-gap: 1rem;
  }
  .documentation-instructions-text {
    width: 100%;
    font-size: 18px;
  }
  .mobile-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
  .documentation-syntax-highlighter {
    font-size: 14px;
  }
  .documentation-content-top {
    flex-direction: column;
    width: 100%;
  }
  .documentation-text {
    width: 100%;
    font-size: 18px;
  }
  .documentation-code {
    width: 100%;
    row-gap: 2.5rem;
  }
  .documentation-code-header {
    font-size: 18px;
  }
  .documentation-api-header-example {
    word-break: break-all;
    height: fit-content;
    font-size: 16px;
  }
  .strong-blue {
    font-size: 18px;
  }
  .documentation-content-bottom {
    width: 100%;
  }
  .documentation-details {
    row-gap: 1rem;
  }
  .example-wrap {
    line-height: 2rem;
  }
  .documentation-details {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .documentation-footer {
    display: flex;
  }
  .parameters {
    padding: 0;
  }
  .documentation-top {
    display: none;
  }
}
