.accordion-header {
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #0b335b;
  background: url(../../../../assets/API-banner.png);
  background-color: #0b335b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 160%;
  opacity: 1;
  transition: all ease 0.2s;
}
.accordion-header:hover {
  opacity: 1;
}
.accordion-header:hover a {
  opacity: 1;
}
.accordion-header a {
  display: flex;
  width: 100%;
  padding: 1rem;
  font-size: 18px;
  font-weight: 500;
  column-gap: 0.5rem;
  text-decoration: none;
  color: #fff;
  transition: all ease 0.2s;
  opacity: 1;
}
.tryitout-header {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
.accordion-header .header-icon {
  height: 1.3rem;
}
.p-toggleable-content .accordion-content .p-toggleable-content-enter-done {
  display: flex;
  width: 100%;
}
