.login-icon {
  height: 4rem;
}
.login-container {
  color: #ffffff;
  width: 24rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 2rem;
  background-color: #0b335b;
  box-shadow: 0 0.5rem 2rem #6464648b;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 1000;
}
.login-header {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.login-header-text {
  font-size: 25px;
  font-weight: 400;
}
.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  row-gap: 0.5rem;
}
.login-input {
  border: 2px solid #ccc;
  background: #f2f3f5;
  box-shadow: 0 0 7px transparent;
  transition: all ease 0.3s;
}
.login-input:focus {
  background: #fff;
  border: 2px solid #0982cc;
  box-shadow: 0 0 7px #0982cc;
}
.login-form-button {
  margin-top: 0.5rem;
}
.login-checkbox {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0;
}
@media all and (max-width: 600px) {
  .login-container {
    width: 100%;
  }
  .instructions {
    font-size: 14px !important;
  }
}
