.underConstruction {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all ease 0.2s;
  overflow: hidden;
  background: #5c4eb3;
  position: relative;
}
.underConstruction-container {
  display: flex;
  width: 50rem;
  flex-direction: column;
  z-index: 1000;
  color: #fbfaf5;
  row-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.underConstruction-icon {
  font-size: 50px;
}
.underConstruction-header {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 2rem;
}
.underConstruction-text {
  font-size: 24px;
  font-weight: 300;
}
.underConstruction-buttons {
  margin-top: 2rem;
  display: flex;
  column-gap: 1rem;
}
.underConstruction-button {
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 20px;
  border-radius: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  z-index: 1000;
  opacity: 1;
  transition: all 200ms ease;
}
.underConstruction-button:hover {
  background-color: #fff;
  color: #5c4eb3;
}
@media all and (max-width: 600px) {
  .underConstruction {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .underConstruction-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .underConstruction-header {
    font-size: 36px;
    margin-bottom: 1rem;
  }
  .underConstruction-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
  }
  .underConstruction-buttons {
    margin-top: 2rem;
    display: flex;
    column-gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .underConstruction-button:hover {
    background-color: transparent;
    color: #fff;
  }
}
