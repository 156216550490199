.tabs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 0.5rem;
  width: 45rem;
  height: 100%;
  background: #f1f1f1;
  word-break: break-all;
  border: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all ease 0.2s;
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 1rem;
  text-align: center;
  width: 50%;
  border: none;
  background: #f2f3f5;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  box-sizing: content-box;
  position: relative;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: #0b335b9e;
  transition: all ease 0.2s;
}
.tabs:not(:last-child) {
  border-right: 1px solid #ccc;
}
.tabs:hover {
  color: #0b335b;
}
.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
  border-radius: none;
  color: #0b335b;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 3px;
  background: #0b335b;
}

.content-tabs {
  flex-grow: 1;
}
.tabs-content {
  background: white;
  padding: 1rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  height: 100%;
  display: none;
}
.active-content {
  display: block;
}
.request-syntax-highlighter {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  max-height: 23rem;
  min-height: 12rem;
  margin: 0;
  transition: all ease 0.2s;
}
@media all and (max-width: 600px) {
  .tabs-container {
    width: 100%;
  }
  .tabs {
    font-size: 18px;
    word-break: keep-all;
  }
  .tabs-content {
    font-size: 14px;
    padding: 0.25rem;
  }
  .request-syntax-highlighter {
    border: none;
  }
}
