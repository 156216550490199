.pageNotFound {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all ease 0.5s;
  overflow: hidden;
  background: #ececec;
  position: relative;
}
.pageNotFound-container {
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.pageNotFound-header {
  display: flex;
  font-size: 250px;
  color: #0b335b;
}
.pageNotFound-subHeader {
  display: flex;
  font-size: 50px;
  color: #0b335b;
}
.pageNotFound-text {
  display: flex;
  font-size: 30px;
  font-weight: 300;
  color: #0b335b;
}
.pageNotFound-buttons {
  margin-top: 2rem;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
}
.pageNotFound-button {
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  font-size: 20px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-color: #0b335b;
  color: #fff;
  z-index: 1000;
  opacity: 1;
  transition: all 200ms ease;
}
.pageNotFound-button:hover {
  opacity: 0.8;
}
@media all and (max-width: 600px) {
  .pageNotFound {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
  .pageNotFound-container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pageNotFound-header {
    font-size: 60px;
  }
  .pageNotFound-subHeader {
    font-size: 40px;
  }
  .pageNotFound-text {
    font-size: 24px;
  }
}
