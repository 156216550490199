.account {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #ececec;
  overflow: hidden;
  position: relative;
}
.account-container {
  width: 40rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.account-header {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #0b335b;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.325);
  width: fit-content;
}
.account-header-text {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}
.account-info {
  color: #0b335b;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #f2f3f5;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.325);
  padding: 1.5rem;
}
.account-info-header {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.account-info-data {
  display: flex;
  flex-direction: column;
}
.account-info-icon {
  display: flex;
  font-size: 24px;
}
.account-button-container {
  display: flex;
  column-gap: 1rem;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  margin-top: 2.2rem;
}
.account-api-docs-button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #0b335b;
  color: #fff;
  font-weight: 500;
  z-index: 1000;
  opacity: 1;
  transition: all 200ms ease;
}
.account-api-regen-button {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #00a300;
  color: #fff;
  font-weight: 500;
  z-index: 1000;
  opacity: 1;
  transition: all 200ms ease;
}
.account-buttons {
  display: flex;
  width: 60%;
  column-gap: 1rem;
  justify-content: flex-start;
}
.account-logout {
  display: flex;
  width: 40%;
  justify-content: flex-end;
}
.account-logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #ff0000;
  color: #fff;
  font-weight: 500;
  z-index: 1000;
  opacity: 1;
  transition: all 200ms ease;
}
.account-email {
  display: flex;
  font-size: 18px;
  font-weight: 500;
}
.account-created {
  display: flex;
  font-size: 14px;
}
.account-key {
  display: flex;
  width: fit-content;
  align-items: center;
  background-color: #fff;
  box-shadow: inset 0px 0px 3px 3px rgba(0, 0, 0, 0.179);
}
.account-key-input {
  width: 30rem;
  height: 2rem;
  border: none;
  font-size: 16px;
  padding: 0.5rem;
  margin: 0.5rem;
}
.key-label {
  margin-top: 2.2rem;
  font-size: 18px;
  font-weight: 500;
}
.account-api-docs-button:hover {
  opacity: 0.8;
}
.account-api-regen-button:hover {
  opacity: 0.8;
}
.account-logout-button:hover {
  opacity: 0.8;
}
.account-api-regen-button:hover .rotate-icon {
  animation: rotation 1s ease-in-out infinite;
  animation-timing-function: linear;
}
.account-copy {
  border: 2px solid #ccc;
  background: #f2f3f5;
  height: 100%;
  width: 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.2s;
}
.account-copy-icon {
  font-size: 22px;
  color: #0b335b;
}
.account-copy:hover {
  cursor: pointer;
  background: #e4e6ea;
}
.account-alerts {
  display: flex;
  flex-direction: column;
}
.account-copy-success {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  height: 4rem;
  width: fit-content;
  column-gap: 0.5rem;
  border-radius: 10px;
  background: #0b335b;
  color: #fff;
  font-size: 20px;
  bottom: 0;
  left: 50%;
  transition: all ease 0.4s;
}
.account-regenerate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  height: 4rem;
  width: fit-content;
  column-gap: 0.5rem;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.9);
  color: #fff;
  font-size: 20px;
  bottom: 0;
  left: 50%;
  transition: all ease 0.4s;
}
.account-login {
  height: fit-content;
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.account-login-text {
  font-size: 20px;
  font-weight: 500;
}
.account-login-button {
  display: flex;
}
.account-icon {
  height: 4rem;
}
@media all and (max-width: 600px) {
  .account {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .account-container {
    width: 100%;
  }
  .account-key {
    width: 100%;
  }
  .account-buttons {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .account-api-docs-button {
    font-size: 16px;
  }
  .account-api-regen-button {
    font-size: 16px;
  }
  .account-login-text {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
