.documentation-tryitout-header {
  display: flex;
  font-size: 20px;
  font-weight: Bold;
  color: #0b335b;
}
.documentation-tryitout-content {
  display: flex;
  width: 65rem;
  column-gap: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #f9f9f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.documentation-tryitout-form {
  display: flex;
  width: 35%;
}
.tryitout-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  row-gap: 0.5rem;
}
.tryitout-label {
  font-weight: 500;
  color: #0b335b;
}
.documentation-tryitout-results {
  display: flex;
  width: 65%;
  height: 100%;
  flex-direction: column;
  max-width: 40rem;
  padding-bottom: 0.5rem;
}
.tryitout-button {
  background-color: #0b335b;
  opacity: 1;
  color: #fff;
  margin-top: 0.5rem;
  transition: all ease 0.2s;
}
.tryitout-button:hover {
  opacity: 0.9;
}
.tryitout-button:disabled {
  opacity: 0.7;
}
.tryitout-input {
  border: solid 2px #ccc;
  box-shadow: 0 0 7px transparent;
  transition: all ease 0.3s;
}
.tryitout-input:focus {
  border: solid 2px #0b335b;
  box-shadow: 0 0 7px #0982cc;
  background: #fff;
}
.tryitout-response {
  display: flex;
  width: 100%;
  height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.tryitout-response-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  word-break: break-all;
}
.tryitout-center {
  align-items: center;
  justify-content: center;
}
.tryitout-loading-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.tryitout-loading-icon {
  position: absolute;
  height: 55px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: opacity 1s infinite ease-in-out;
  -o-animation: opacity 1s infinite ease-in-out;
  -ms-animation: opacity 1s infinite ease-in-out;
  -moz-animation: opacity 1s infinite ease-in-out;
  animation: opacity 1s infinite ease-in-out;
}
.loading-spinner {
  width: 85px;
  height: 85px;
  border: 2px solid #0b335b;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}
.tryitout-image-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 500;
  opacity: 0.3;
}
.tryitout-finfetch-icon {
  height: 55px;
  width: auto;
  filter: grayscale(100%);
}
@media all and (max-width: 600px) {
  .documentation-tryitout-content {
    flex-direction: column;
    width: 100%;
    padding: 0.7rem;
  }
  .documentation-tryitout-form {
    width: 100%;
  }
  .documentation-tryitout-results {
    margin-top: 1rem;
    width: 100%;
    padding-bottom: 0;
  }
  .tryitout-response {
    height: 12rem;
    max-height: 16rem;
  }
  .tryitout-response-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .documentation-code-header {
    font-size: 16px;
    font-weight: 500;
    color: #0b335b;
  }
}
